import { Component } from '@angular/core';
import { SvgIconComponent } from '../../shared/ui/utils/svg-icon/svg-icon.component';
import { combineLatest, map } from 'rxjs';
import { Store } from '@ngrx/store';
import * as selector from '../state';
import { AsyncPipe, CommonModule } from '@angular/common';
import * as actions from '../state/actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-video-resource',
  standalone: true,
  imports: [SvgIconComponent, AsyncPipe, CommonModule],

  templateUrl: './video-resource.component.html',
  styleUrl: './video-resource.component.scss',
})
export class VideoResourceComponent {
  videos$ = this.store.select(selector.getVideos);
  isQuizComplete$ = this.store.select(selector.getIsModuleQuizComplete);
  isQuizReady$ = this.store.select(selector.getIsQuizReady);
  currentModuleQuizThumbnail$ = this.store.select(
    selector.getCurrentModuleQuizThumbnail,
  );
  getAvailableCertificate$ = this.store.select(
    selector.getAvailableCertificate,
  );
  getCurrentLevelId$ = this.store.select(selector.getCurrentLevelId);
  getCurrentLevel$ = this.store.select(selector.getCurrentLevel);

  vm$ = combineLatest([
    this.videos$,
    this.isQuizComplete$,
    this.isQuizReady$,
    this.currentModuleQuizThumbnail$,
    this.getAvailableCertificate$,
    this.getCurrentLevelId$,
    this.getCurrentLevel$
  ]).pipe(
    map(
      ([
        videos,
        isQuizComplete,
        isQuizReady,
        currentModuleQuizThumbnail,
        getAvailableCertificate,
        getCurrentLevelId,
        getCurrentLevel,
      ]) => ({
        videos,
        isQuizComplete,
        isQuizReady,
        currentModuleQuizThumbnail,
        getAvailableCertificate,
        getCurrentLevelId,
        getCurrentLevel
      }),
    ),
  );

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  playVideo(index: number) {
    this.store.dispatch(
      actions.DashboardPageActions.userClickedPlayVideo({
        videoIndex: index,
      }),
    );
  }

  displayIndex(index: number) {
    return index + 1;
  }

  goToQuiz() {
    this.router.navigate(['/quiz']);
  }

  downloadCertificate(levelId: number) {
    this.store.dispatch(
      actions.DashboardPageActions.activityTrackerDispatchedDownloadCertificate(
        {
          levelId: levelId,
        },
      ),
    );
  }
}
