@if (vm$ | async; as vm) {
<div class="module-videos">
  @for (video of vm.videos; track $index) {
  <div class="single-resource-container">
    <h3>{{ displayIndex($index) }}. {{ video.title }}</h3>

    <article class="card" [ngClass]="{ completed: video.completed }" tabindex="1">
      <img [src]="video.thumbnailUrl" alt="{{ video.title }}" />

      <div class="card-overlay">
        <button class="overlay_container" (click)="playVideo($index)">
          <!-- Default play icon -->
          @if (video.completed) {
          <app-svg-icon [icon]="'icon-tick'" [title]="'Completed'" class="completed"></app-svg-icon>
          } @else {
          <app-svg-icon [icon]="'icon-play-circle'" [title]="'Play Video'" class="play"></app-svg-icon>
          }
        </button>
      </div>
    </article>
  </div>
  }

  @if(vm.isQuizReady || vm.isQuizComplete) {
  <div class="quiz-container">
    <article class="card" [ngClass]="{ completed: vm.isQuizComplete}" tabindex="1">
      <img [src]="vm.currentModuleQuizThumbnail" [alt]="'Take the Quiz Button'" />

      <div class="card-overlay">
        <button class="overlay_container" (click)="goToQuiz()">
          <!-- Default play icon -->
          @if (vm.isQuizComplete) {
          <app-svg-icon [icon]="'icon-replay'" [title]="'Completed'" class="completed"></app-svg-icon>
          } @else {
          <app-svg-icon [icon]="'icon-play-circle'" [title]="'Play Video'" class="play"></app-svg-icon>
          }
        </button>
      </div>
    </article>
  </div>
  }

  @if (vm.getAvailableCertificate) {
  <div class="certificate-container">
    <h3>{{ vm.getCurrentLevel }} certificate</h3>
    <article class="card" tabindex="1">
      <img [src]="'/assets/certificate.png'" [alt]="'Certificate Button'" />

      <div class="card-overlay">
        <button class="overlay_container" (click)="downloadCertificate(vm.getCurrentLevelId)">
          <app-svg-icon [icon]="'icon-download'" [title]="'Download Certificate'"></app-svg-icon>
        </button>
      </div>
    </article>
  </div>
  }
</div>
}